<template>
    <div class="widget-ratio-container" :style="containerStyle">
        <div class="widget-ratio-content" :style="contentStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/*
This Component is used to rescale all content inside based on the size of the container.
Allows to overlap content on top of an image
 */
export default {
    name: `widget-ratio-container`,
    props: {
        backgroundImage: {type: String},
        backgroundColor: {type: String},
        unit: {type: String, default: `px`},
        minMargins: {type: Number, default: 30},
        width: {type: Number, required: true},
        height: {type: Number, required: true}
    },
    data() {
        return {
            containerWidth: 0,
            containerHeight: 0
        }
    },
    created() {
        this.updateSize();
        window.addEventListener(`resize`, this.updateSize);
    },
    destroyed() {
        window.removeEventListener(`resize`, this.updateSize);
    },
    computed: {
        containerStyle() {
            let style = {};
            if (this.minMargins) {
                style.padding = this.minMargins + this.unit;
            }
            if (this.backgroundColor) {
                style.backgroundColor = this.backgroundColor;
            }
            return style;
        },
        minWidth() {
            return this.width + this.minMargins * 2;
        },
        minHeight() {
            return this.height + this.minMargins * 2;
        },
        contentStyle() {
            let style = {
                width: this.width + this.unit,
                height: this.height + this.unit
            };
            if (this.backgroundImage) {
                style.backgroundImage = `url("${this.backgroundImage}")`;
            }
            if (this.containerWidth < this.minWidth || this.containerHeight < this.minHeight) {
                let scale = 1;
                if (this.containerWidth / this.minWidth < this.containerHeight / this.minHeight) {
                    scale = this.containerWidth / this.minWidth;
                } else {
                    scale = this.containerHeight / this.minHeight;
                }
                style.zoom = scale;
            }
            return style;
        }
    },
    methods: {
        updateSize() {
            this.containerWidth = window.innerWidth;
            this.containerHeight = window.innerHeight;
        }
    }
}
</script>

<style lang="scss" scoped>
.widget-ratio-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    //outline: 1px dashed magenta;

    .widget-ratio-content {
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
</style>
