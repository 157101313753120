<template>
    <widget-container v-if="widget" :widget="widget" class="widget-weekly-menu" name="Weekly Menu Schedule" centered>
        <widget-ratio-container v-if="widget.loaded" :width="1764" :height="883" background-color="#cbd3d9" :background-image="widget.config.style.page.background">
            <widget-block type="title" direction="vertical" :style="widget.config.style.title">
                <template v-if="widget.config.languages.mode === `all` && !widget.config.languages.switch">
                    <div v-for="(language, index) in widget.languages.available" :key="`title-${language}`">
                        <hr class="divider" v-if="index > 0">
                        <p>{{widget.config.title.value[language]}}</p>
                    </div>
                </template>
                <template v-else>
                    <p>{{widget.config.title.value[currentLanguage]}}</p>
                </template>
            </widget-block>

            <widget-block type="content" direction="vertical" :style="widget.config.style.content">
                <template v-if="widget.config.languages.mode === `all` && !widget.config.languages.switch">
                    <div v-for="(language, index) in widget.languages.available" :key="`value-${language}`">
                        <hr class="divider" v-if="index > 0">
                        <div v-for="field in fieldsToDisplay" :key="`value-${language}-${field}`">
                            <template v-if="currentDay[field.id] && currentDay[field.id][language].value">
                                <span class="field-name" v-if="widget.config.display.field_name" :style="widget.config.style.field_name">
                                    {{field.name[language]}}
                                </span>
                                <span class="field-value">
                                    {{currentDay[field.id][language].value}}
                                </span>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-for="field in fieldsToDisplay">
                        <template v-if="currentDay[field.id] && currentDay[field.id][currentLanguage].value">
                            <span class="field-name" v-if="widget.config.display.field_name" :style="widget.config.style.field_name">
                                {{field.name[currentLanguage]}}:
                            </span>
                            <span class="field-value">
                                {{currentDay[field.id][currentLanguage].value}}
                            </span>
                        </template>
                    </div>
                </template>
            </widget-block>
        </widget-ratio-container>
    </widget-container>
</template>

<script>
import WidgetBlock from "@/layout/widget-block.vue";
import WidgetRatioContainer from "@/layout/widget-ratio-container.vue";
import {Widget} from "../helpers/Widget.js";

export default {
    name: `widget-daily-menu`,
    components: {WidgetRatioContainer, WidgetBlock},
    data() {
        return {
            widget: null,
            currentDayIndex: 0,
            currentWeekIndex: 0
        }
    },
    computed: {
        collectionConfig() {
            return this.widget.dataCollection.config;
        },
        collectionValues() {
            return this.widget.dataCollection.values;
        },
        currentLanguage() {
            return this.widget.currentLanguage;
        },
        currentWeek() {
            return this.collectionValues.weeks[this.currentWeekIndex];
        },
        currentDay() {
            const week = this.currentWeek;
            if (!week) {
                return null;
            }
            return week.days[this.currentDayIndex];
        },
        fieldsToDisplay() {
            let fields = [];
            for (let field of this.widget.config.fields) {
                if (field.display === true) {
                    fields.push(field);
                }
            }
            return fields;
        }
    },
    mounted() {
        this.loadWidget();
    },
    activated() {
        this.loadWidget();
    },
    methods: {
        async loadWidget() {
            if (this.widget) {
                return;
            }
            this.widget = new Widget(`weekly-menu`, this.$route.params.id);

            // if (this.widget.config.languages.mode === `all`) {
            //     this.currentLanguage = this.widget.config.languages.available[0];
            //     if (this.widget.config.languages.switch) {
            //         setInterval(() => {
            //             this.currentLanguageIndex = (this.currentLanguageIndex + 1) % this.widget.config.languages.available.length;
            //             this.currentLanguage = this.widget.config.languages.available[this.currentLanguageIndex];
            //         });
            //     }
            // } else {
            //     this.currentLanguage = this.widget.config.languages.mode;
            // }

            await this.widget.load();
            this.updateDate();
        },
        updateDate() {
            this.currentDayIndex = this.$moment().day();

            const firstDayOfWeek = this.$moment().day(1);
            const deltaWeek = this.$moment(firstDayOfWeek).diff(this.$moment(this.collectionConfig.firstCycleDate), `week`);
            this.currentWeekIndex = Math.modulo(deltaWeek, this.collectionConfig.weekCycleCount);

            this.$forceUpdate();
        }
    }
}
</script>

<style lang="scss" scoped>
.widget-block {
    ::v-deep .divider {
        border-top: 5px solid rgba(white, 0.5);
        display: block;
        width: 50%;
        border-radius: 5px;
    }

    &.title {
        left: 0;
        top: 0;
        bottom: 0;
        width: 23.7%;

        padding: 2%;
        font-size: 55pt;
        font-weight: 700;
        color: white;
    }

    &.content {
        left: 25%;
        top: 3%;
        right: 0;
        bottom: 3%;

        padding: 2%;
        font-size: 45pt;
        color: #333;
        font-weight: 500;

        .field-name {
            font-size: 85%;
            font-weight: 700;
        }

        ::v-deep hr {
            border-top-color: rgba(black, 0.5);
        }
    }
}
</style>
