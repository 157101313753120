import Network from "../../vue-components/helpers/Network.js";

export class Widget {
    constructor(type, id) {
        this.type = type;
        this.id = id;
        this.config = null;
        this.data = null;
        this.style = null;
        this.dataCollection = null;

        this.currentLanguage = `en`;
        this.languages = {
            available: [`en`],
            switch: false,
            switchDelay: 5000,
            switchCallback: null
        }

        this.loading = true;
        this.loaded = false;
        this.error = false;
    }

    async load() {
        return new Promise((resolve, reject) => {
            Network.get(`/api/widgets/${this.type}/${this.id}`)
                .then(resp => {
                    const widget = resp.data;
                    for (let key in widget) {
                        this[key] = widget[key];
                    }
                    this.loadConfig(widget.widget_config);
                    this.loadData(widget.widget_data);
                    this.loadDataCollection(widget.data_collection);
                    this.loaded = true;
                    this.loading = false;
                    resolve(this);
                })
                .catch(e => {
                    this.error = true;
                    this.loading = false;
                    console.error(e);
                    reject(e);
                });
        });
    }

    loadConfig(config) {
        if (!config) {
            throw new Error(`Missing Widget Config`);
        }

        this.config = config;

        if (this.config.languages) {
            this.languages.available = this.config.languages.available;
            if (this.languages.available.length > 0) {
                this.setCurrentLanguage(this.languages.available[0]);
            }
            this.languages.switch = this.config.languages.switch;
            this.languages.switchDelay = this.config.languages.switch_delay;
            if (this.languages.switch && this.languages.available.length >= 2) {
                this.enableLanguageSwitch();
            }
        }
    }

    loadData(data) {
        if (!data) {
            if (this.config && this.config.data_required === true) {
                throw new Error(`Missing Widget Data`);
            } else {
                console.log(`Widget doesn't need Data`);
            }
        }

        this.data = this.widget_data;
    }

    loadDataCollection(data) {
        if (!data) {
            if (this.data && this.data.data_collection_id && !this.data_collection) {
                throw new Error(`Missing Widget Data Collection`);
            } else {
                console.log(`Widget doesn't need Data Collection`);
            }
        }

        this.dataCollection = this.data_collection;
    }

    enableLanguageSwitch() {
        setInterval(this.switchLanguage.bind(this), this.languages.switchDelay * 1000);
    }

    switchLanguage() {
        const languageIndex = this.languages.available.indexOf(this.currentLanguage);
        if (languageIndex >= 0) {
            this.setCurrentLanguage(this.languages.available[(languageIndex + 1) % this.languages.available.length]);
        }
    }

    setOnLanguageSwitchCallback(callback) {
        this.languages.switchCallback = callback;
    }

    setCurrentLanguage(language) {
        this.currentLanguage = language;

        if (typeof this.languages.switchCallback === `function`) {
            this.languages.switchCallback(language);
        }
    }
}
