<template>
    <div class="widget-block" :class="[type]" :style="styleComputed">
        <slot></slot>
    </div>
</template>

<script>
import CssHelper from "../../vue-components/helpers/CssHelper.js";

export default {
    name: `widget-block`,
    props: {
        type: {type: String, default: `content`},
        direction: {type: String, default: `horizontal`}, // horizontal | vertical
        blockStyle: {},
        hAlign: {type: String, default: `center`},
        vAlign: {type: String, default: `center`}
    },
    computed: {
        styleComputed() {
            let style = {
                color: this.color,
                flexDirection: this.direction === `horizontal` ? `row` : `column`,
                justifyContent: this.hAlign,
                alignItems: this.vAlign,
            };
            if (this.blockStyle) {
                style = Object.assign(style, this.blockStyle);
                if (typeof style.margin === `object`) {
                    let margins = CssHelper.addUnitToObject(style.margin, `px`);
                    style.margin = CssHelper.mergeMargins(margins);
                }
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
.widget-block {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    //outline: 1px dashed magenta;
}
</style>
