export default class CssHelper {
    static mergeMargins(margins) {
        return `${margins.top} ${margins.right} ${margins.bottom} ${margins.left}`;
    }

    static addUnitToObject(values, unit = `px`) {
        if (!values) {
            return values;
        }
        let obj = {}
        for (let key in values) {
            obj[key] = CssHelper.addUnitToValue(values[key], unit);
        }
        return obj;
    }

    static addUnitToValue(value, unit = `px`) {
        if (value === null || value === undefined) {
            return value;
        }

        if (typeof value === `number`) {
            return value + unit;
        }

        return value;
    }
}
